/* theme customizations for knidal */
$theme-colors: (
  'primary': #d00000,
  'secondary': #001f80,
  'error': #600000,
  'dark': #333333,
);

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';

/*****************************************************/
/* Customize checkbox look & feel                    */
/*****************************************************/

input[type='checkbox'] {
  margin-right: 5px;
  cursor: default;
  font-size: 8px;
  width: 12px;
  height: 12px;
  position: relative;
}

input[type='checkbox']:after {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 0;
  content: ' ';
  color: #fff;
  display: inline-block;
  visibility: visible;
  padding: 0px 2px;
  border-radius: 2px;
}

input[type='checkbox']:checked:after {
  content: '✓';
  font-size: 8px;
  font-weight: bold;
  background-color: theme-color('dark');
  padding: 0px 2px;
  border-radius: 2px;
}

/*****************************************************/
/* Customize the react-ui-tree */
/*****************************************************/
.m-node.placeholder {
  border: 2px dashed theme-color('secondary');
}

.m-node .inner {
  width: 100%;
  display: inline-flex;
  color: black;
  font-size: 16px;
  padding-left: 0;
  align-items: center;
}

.m-node .node {
  display: inline-block;
  width: 100%;
  padding: 4px 4px;
}

.m-node .node.is-active {
  background-color: gray('200');
}

.m-node .node.is-non-global {
  color: theme-color('primary');
}

.m-node .caret-right:before {
  padding: 4px 4px;
  vertical-align: middle;
}

.m-node .caret-down:before {
  padding: 4px 4px;
  vertical-align: middle;
}

.m-node .collapse {
  position: relative;
}

/*****************************************************/
/* Create new custom classes using bootstrap classes */
/*****************************************************/
.dashboard-table {
  @extend .table-striped;
  @extend .table-bordered;
  width: 100%;
  th {
    position: sticky;
    top: 0;
    background-color: gray('600');
    color: white;
  }
  tbody tr {
    &:hover {
      background-color: #ecf3f8;
    }
  }
}

.dashboard-table {
  img {
    width: 4rem;
    height: 4rem;
    border-radius: 10px;
  }
}

.variant-table {
  @extend .table-bordered;
  th {
    position: sticky;
    top: 0;
    background-color: gray('600');
    color: white;
  }
}

.edit-access-table {
  @extend .table-striped;
  @extend .table-bordered;

  th {
    position: sticky;
    top: 0;
    background-color: gray('600');
    color: white;
  }
}

.btn-cms-header-outline {
  @extend .btn-outline-dark;
  margin-left: 12px;
  margin-right: 12px;
}

.btn-cms-header {
  @extend .btn-secondary;
  margin-left: 12px;
  margin-right: 12px;
}

.navbar-light {
  @extend .navbar-light;
  height: 64px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: white;
}

.custom-image-style {
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 10px;
}

.media-bite-card {
  background: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
}

.media-bite-card img,
video {
  width: 100%;
  height: auto;
}

.media-bite-card .wmde-markdown {
  background: #f0f0f0;
  padding-inline: 1rem;
  padding-block: 0.5rem;
  font-size: smaller;
}

.media-bite-card audio::-webkit-media-controls-panel {
  padding: 0 !important;
}

.media-bite-card audio::-webkit-media-controls-enclosure {
  border-radius: 0 !important;
  background-color: #f0f0f0;
}
