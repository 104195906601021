@import 'custom';

:root {
  --neutral-muted-purple: #6a457a;
  --color-on-muted-purple: #ffffff;
  --muted-purple-hover-color: #7f6095;
}

.cursor-pointer {
  cursor: pointer;
}

.dashboard-table-container {
  overflow-y: auto;
  max-height: 480px;
}

.editacess-table-container {
  overflow-y: auto;
  max-height: 450px;
}

.fa-link {
  color: gray('800');
  text-decoration: none;
  background-color: transparent;
}

.vertical-line-right {
  border-right: thin solid black;
}

.vertical-line-left {
  border-left: thin solid black;
}

.is-non-global {
  color: theme-color('primary');
}

/* Content Bite Classes */
.contentbite {
  padding: 0;
}

.contentbite-chosen {
  background-color: gray('400');
}

.h-screen {
  height: 100vh;
}

.text-error-color {
  color: #d10000;
}

.buttonClass {
  border-radius: 6px;
  font-weight: 700;
  height: 34px;
  width: 100%;
}

button {
  border-radius: 0;
  border: none;
}

[type='file'] {
  height: 0;
  overflow: hidden;
  width: 0;
}

[type='file'] + label {
  background: #333333;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 1rem;
  outline: none;
  padding: 1rem 50px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;

  &:hover {
    background-color: darken(#333333, 10%);
  }
}

/* neutral button */

.neutral-button {
  background: var(--neutral-muted-purple);
  color: var(--color-on-muted-purple);
  font-size: large;
  padding-inline: 8px;
  padding-block: 4px;
  align-content: center;
}

.neutral-button:hover {
  background: var(--muted-purple-hover-color);
}

.wmde-markdown h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  display: none;
}

// content bite css
.youtube-action-buttons {
  display: none;
}

.youtube-video-bite-content:hover {
  .youtube-action-buttons {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;
    width: inherit;
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.video-action-buttons {
  display: none;
}

.video-bite-content:hover {
  .video-action-buttons {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;
    width: inherit;
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.image-action-buttons {
  display: none;
}

.image-bite-content:hover {
  .image-action-buttons {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;
    width: inherit;
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.audio-action-buttons,
.text-action-buttons,
.tree-section-node-item {
  visibility: hidden;
}

.audio-bite-content:hover {
  .audio-action-buttons {
    visibility: visible;
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.text-bite-content:hover {
  .text-action-buttons {
    visibility: visible;
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.tree-section-node:hover {
  .tree-section-node-item {
    visibility: visible;
  }
}

.line-clamp-2 {
  width: 208px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  min-width: 208px;
}

.dnd-sortable-tree_simple_tree-item {
  border: none !important;
  padding: 0 !important;
}

.search-highlight {
  background: #ffeaa7;
}

.search-highlight .wmde-markdown {
  background: transparent !important;
}

.highlighter {
  background: #ffb04c;
}
