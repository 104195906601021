.search-box {
  width: 350px;
  position: relative;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.search-input {
  width: 100%;
  font-size: 16px;
  padding: 8px 45px 8px 15px;
  color: black;
  border-radius: 6px;
  border: none;
  transition: all 0.4s;
  border: 1px solid gray;
  border-radius: 8px;
}

.search-input:focus {
  outline: none;
}

.search-btn {
  background-color: transparent;
  font-size: 18px;
  padding: 6px 9px;
  margin-left: -45px;
  border: none;
  color: #6c6c6c;
  transition: all 0.4s;
  z-index: 10;
}

.search-btn:hover {
  transform: scale(1.2);
  cursor: pointer;
  color: black;
}

.search-btn:focus {
  outline: none;
  color: black;
}
