body {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Video and Image Card Start */
.image-video-card {
  background: #f6f8fa;
  border-radius: 8px;
}

.image-video-card img,
video {
  border-radius: 8px 8px 0 0;
  max-width: 100%;
  height: auto;
}

.image-video-card .wmde-markdown {
  padding: 1rem;
  border-radius: 0 0 8px 8px;
  background: #f6f8fa;
}

/* Loader */

.image-loader {
  border: 4px solid var(--neutral-muted-purple); /* Loader color */
  border-top: 4px solid #ffffff; /* Background color behind the loader */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin: 20px auto; /* Center the loader */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.retry-btn {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 2px solid gray;
  border-radius: 0.6em;
  color: black;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 20px;
  padding: 0.7em 4em;
  text-decoration: none;
  text-align: center;
  font-weight: 700;
}

.object-cover {
  object-fit: cover;
}

/* .wmde-markdown-color {
  padding: 2px 10px;
} */

/* .wmde-markdown-color:hover {
  background-color: #e9ecef;
  box-shadow: 0px 03px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
} */
